import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import ProjectExplorer from '../project-explorer/ProjectExplorer';

const FolderExplorerModal = forwardRef(({ onFolderChosen, currentFolder }, ref) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(currentFolder);

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setIsModalOpen(true);
        },
    }));

    const handleOk = () => {
        if (selectedFolder) {
            onFolderChosen(selectedFolder);
        }
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedFolder(null);
    };

    const handleFolderSelect = (folder) => {
        setSelectedFolder(folder);
    };

    return (
        <Modal
            title={t('general.select_folder')}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={window.innerWidth * 0.65}
            bodyStyle={{padding: 0, height: window.innerHeight * 0.80, overflow: 'auto'}}
            okButtonProps={{ disabled: !selectedFolder }}
            destroyOnClose
            keyboard={false}
            centered
        >
            <ProjectExplorer
                onlyExplorer
                onItemSelected={handleFolderSelect}
                folder={currentFolder}
                onlyFolder
            />
        </Modal>
    );
});

FolderExplorerModal.displayName = 'FolderExplorerModal';

export default FolderExplorerModal; 